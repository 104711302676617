import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import api from "../services/api";

const IssuesUpdatesPage = () => {
  const { id } = useParams(); // Get the issue ID from the URL
  const [issue, setIssue] = useState(null);
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    // Fetch the issue and its updates
    const fetchIssue = async () => {
      try {
        // Fetch the issue details by ID
        const issueResponse = await api.get(`/issues/${id}`);
        setIssue(issueResponse.data);

        // Fetch the updates for the issue
        const updatesResponse = await api.get(`/issues/${id}/updates`);
        setUpdates(updatesResponse.data);
      } catch (err) {
        console.error("Error fetching issue or updates:", err);
      }
    };

    fetchIssue();
  }, [id]);

  return (
    <div className="container mt-4">
      {issue ? (
        <>
          <h1>{issue.title}</h1>
          <small>
            <b>Reported:</b>{" "}
            {(() => {
              const formattedDate = new Date(issue.start_time).toLocaleString(
                undefined,
                {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                }
              );

              return formattedDate.replace(",", " at");
            })()}
          </small>
          <p>
            <b>Issue Description:</b> {issue.description}
          </p>

          {/* Status with tick or cross */}
          <h4>
            <b>Status:</b> {issue.progress}{" "}
            {issue.progress === "Resolved" ? (
              <span className="text-success ml-2">
                <i className="fa fa-check-circle"></i> {/* Green tick */}
              </span>
            ) : (
              <span className="text-danger ml-2">
                <i className="fa fa-times-circle"></i> {/* Red cross */}
              </span>
            )}
          </h4>

          <h4>Updates:</h4>
          <ul className="list-group mt-3">
            {updates.length > 0 ? (
              updates.map((update) => (
                <li key={update.id} className="list-group-item">
                  <p style={{ whiteSpace: "pre-wrap" }}>
                    <b>Update:</b> {update.update_text}
                  </p>
                  <p>
                    <small>
                      <b>Updated on:</b>{" "}
                      {(() => {
                        const formattedDate = new Date(
                          update.created_at
                        ).toLocaleString(undefined, {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        });

                        return formattedDate.replace(",", " at");
                      })()}
                    </small>
                  </p>
                </li>
              ))
            ) : (
              <p>There are currently no updates available for this issue.</p>
            )}
          </ul>
        </>
      ) : (
        <p>Loading issue details...</p>
      )}
    </div>
  );
};

export default IssuesUpdatesPage;
